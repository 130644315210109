import React, { useState } from 'react';
import styles from './MarketingPage.module.scss';
import MarketingPage1 from '../components/marketing/MarketingPage1';
import MarketingPage2 from '../components/marketing/MarketingPage2';
import MarketingPage3 from '../components/marketing/MarketingPage3';
import MarketingPage4 from '../components/marketing/MarketingPage4';
import MarketingPage5 from '../components/marketing/MarketingPage5';
import MarketingPage6 from '../components/marketing/MarketingPage6';
import MarketingPage7 from '../components/marketing/MarketingPage7';
import MarketingPage8 from '../components/marketing/MarketingPage8';
import MarketingPage9 from '../components/marketing/MarketingPage9';
import MarketingPage10 from '../components/marketing/MarketingPage10';
import MarketingFooter from '../components/layout/MarketingFooter';
import MarketingWarningPage from '../components/marketing/MarketingWarningPage';
import AppStoresBanner from '../components/modals/AppStoresBanner';
import MarketingPage11 from '../components/marketing/MarketingPage11';

const MarketingPage = () => {
    const [showBanner, setShowBanner] = useState(false);

    return (
        <div className={styles.container}>
            <MarketingPage1 setShowBanner={setShowBanner} />
            <MarketingPage3 />
            <MarketingPage2 />
            <MarketingPage6 setShowBanner={setShowBanner} />
            <MarketingPage4 />
            <MarketingPage5 />
            <MarketingPage7 />
            <MarketingPage10 />
            <MarketingPage9 />
            <MarketingPage11 />
            <MarketingPage8 setShowBanner={setShowBanner} />
            <MarketingFooter />
            <MarketingWarningPage />
            <AppStoresBanner
                showBanner={showBanner}
                setShowBanner={setShowBanner}
            />
        </div>
    );
};

export default MarketingPage;
