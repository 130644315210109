/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = () => (
    <div className={styles.container}>
        <div className={styles.largeTitle}>
            Terms of Service
        </div>
        <div className={styles.title}>
            Summary of our Terms
        </div>
        These Terms of Service (“Terms”) are part of the User Agreement– a legally binding contract governing your use of SellCorp. You should read these Terms of Service (“Terms”) in full, but here are a few key things you should take away:
        <ul>
            <br />
            <li>
                <span className={styles.bold}>Pricing: </span>
                Users should refer to the Apple App Store or Google Play Store pricing for the most up-to-date fees.
            </li>
            <li>
                <span className={styles.bold}>Compliance with User Agreement and Laws: </span>
                You are responsible for your use of SellCorp and any content you post. You must adhere to this User Agreement, its included policies, and all applicable laws.
            </li>
            <li>
                <span className={styles.bold}>Acceptable Use: </span>
                Access to SellCorp should only be through the provided interfaces. For example, scraping or bypassing technical limitations is not allowed.
            </li>
            <li>
                <span className={styles.bold}>Enforcement Rights: </span>
                SellCorp reserves the right to enforce these Terms, including removing your content or limiting your access. Account suspension can occur for various reasons, including legal concerns or policy violations.
            </li>
            <li>
                <span className={styles.bold}>Intellectual Property: </span>
                You retain ownership of any content you post. You grant SellCorp a non-exclusive, royalty-free licence to use, modify, and distribute your content.
            </li>
            <li>
                <span className={styles.bold}>No Sale of User Content to Third Parties: </span>
                We do not sell your content to third-party companies.
            </li>
            <li>
                <span className={styles.bold}>Data Protection and Privacy: </span>
                SellCorp complies with data protection laws, including Regulation (EU) 2016/679 (GDPR). Your data is processed as described in our Privacy Notice.
            </li>
            <li>
                <span className={styles.bold}>Jurisdiction: </span>
                These Terms are governed by the laws of England and Wales, and disputes will be resolved by the courts of England and Wales.
            </li>
            <li>
                <span className={styles.bold}>Changes to Terms: </span>
                These Terms may be updated periodically. Continued use of SellCorp after updates constitutes acceptance of the new Terms.
            </li>
            <br />
        </ul>

        <div className={styles.title}>
            Introduction
        </div>
        <br />
        Welcome to SellCorporation, your dedicated platform for connecting entrepreneurs and investors. SellCorporation is the trading name of Corporation Sell Limited, a company registered at 103 Barney Close, London, SE7 8ST, United Kingdom, and registered in England and Wales under number 12966882. Our mobile application, SellCorp, is part of the SellCorporation platform.
        <br />
        SellCorp app is a discovery platform that does not facilitate investments or financial transactions. SellCorp operates as a technology networking platform.
        <br />
        By accessing
        {' '}
        <a
            className={styles.link}
            href="https://www.sellcorporation.com/"
        >
            www.sellcorporation.com
        </a>
        , using the SellCorp mobile application on iOS and Android, or utilising any of our related services, websites, applications, or tools (collectively referred to as “SellCorporation”), you agree to comply with and be bound by these Terms of Service.
        <br />
        Please be advised that SellCorporation is a platform designed to facilitate connections between entrepreneurs and investors. We do not engage in any fundraising activities, nor do we handle or receive funds on behalf of our users.
        <br />
        These Terms of Service constitute a legally binding agreement between you and Corporation Sell Limited. The terms are effective as of 29 January 2025 for current users, and upon acceptance for new users. By clicking the “Register” button when creating a SellCorp account, or by otherwise accessing or using SellCorporation, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
        <br />
        Your use of SellCorporation is also governed by our Privacy Policy. We strongly recommend that you review our Privacy Policy to understand our practices related to data protection and privacy.
        <br />
        SellCorporation is currently available for download and use exclusively within European countries. By using SellCorporation, you confirm that you are accessing the service from a European country.
        <br />
        <div className={styles.title}>
            Publication, Content, and Marketing
        </div>
        <br />
        <span className={styles.bold}>Publication and Unpublishing: </span>
        When you publish your company details on the SellCorp platform, they will remain visible until you choose to unpublish them or delete your account. We may introduce a feature in future updates that sets an expiration date for each published company. To unpublish your company or delete your account, please follow the guidelines provided within the SellCorp platform. If you wish to delete a specific company without deleting your entire account, you can contact us at info@sellcorporation.com for assistance.
        <br />
        <br />
        <span className={styles.bold}>Data Deletion: </span>
        If you choose to delete your SellCorp account, all your data—including any published companies—will be permanently removed from our platform. Should you opt to unpublish a company, the company details will no longer be visible to other users but will remain stored on our servers until you delete your account.
        <br />
        <br />
        <span className={styles.bold}>Marketing Permissions: </span>
        By publishing your company details or registering as an investor on SellCorp, you hereby grant us the permission to feature your company name, logo, or investor names in our marketing materials. This permission extends to, but is not limited to, advertising campaigns, promotional materials, and public announcements stating that your company is featured on, or that you are registered as an investor with, the SellCorp platform.
        <br />
        <br />
        <div className={styles.title}>
            Using SellCorp
        </div>
        <br />
        <span className={styles.bold}>Acceptance of Terms: </span>
        By accessing or using the SellCorp platform, you agree to adhere to all terms and conditions outlined in this document, as well as any applicable laws and regulations.
        <br />
        <br />
        <span className={styles.bold}>Legal Compliance: </span>
        You agree not to use SellCorp in a manner that violates any local, state, national, or international laws or regulations.
        <br />
        <br />
        <span className={styles.bold}>Content and Conduct: </span>
        You are solely responsible for the information, presentations, or any other content that you publish or display on SellCorp. You agree not to post misleading, fraudulent, harmful, threatening, abusive, defamatory, obscene, or otherwise objectionable content.
        <br />
        <br />
        <span className={styles.bold}>Due Diligence: </span>
        Both entrepreneurs and investors are responsible for conducting their own due diligence when entering into discussions or agreements. SellCorp is not responsible for conducting due diligence on behalf of users and is not liable for any consequences arising from interactions between users.
        <br />
        <br />
        <span className={styles.bold}>Third-Party Rights: </span>
        You agree not to infringe upon the rights of third parties, including but not limited to intellectual property rights, privacy rights, and contractual rights.
        <br />
        <br />
        <span className={styles.bold}>Marketing Permissions: </span>
        By publishing your company or registering as an investor on SellCorp, you grant us explicit permission to feature your company name, logo, or investor name in our marketing materials.
        <br />
        <br />
        <span className={styles.bold}>Technical Usage: </span>
        You agree not to distribute viruses or any other harmful technologies that may affect the functionality of SellCorp or the interests or property of SellCorp users. You will not impose an unreasonable load on our infrastructure or interfere with the proper working of SellCorp.
        <br />
        <br />
        <span className={styles.bold}>Automated Access: </span>
        You will not use any automated means, including bots, scrapers, or spiders, to access SellCorp or collect user information without our express written permission.
        <br />
        <br />
        <span className={styles.bold}>Data Handling and User Information: </span>
        You agree not to harvest or otherwise collect information about other users, including email addresses, without their explicit consent. Your use of SellCorp is also governed by our Privacy Policy, and we recommend reviewing it to understand our data handling practices.
        <br />
        <br />
        <span className={styles.bold}>Intellectual Property: </span>
        You agree not to copy, modify, or distribute content from SellCorp, or SellCorp’s copyrights and trademarks, without express consent from the respective owners.
        <br />
        <br />
        <span className={styles.bold}>Penalties and Enforcement: </span>
        We reserve the right to refuse or delete content, restrict or terminate user access, or take any other corrective action if we believe you are violating these Terms of Service or behaving suspiciously. We may also inform other SellCorp users who have been in contact with you and recommend that they exercise caution.
        <br />
        <br />
        <span className={styles.bold}>Additional Software and Tools: </span>
        You agree not to use any tools, extensions, or other software designed to modify, supplement, or remove any of SellCorp’s functionalities.
        <br />
        <br />
        <span className={styles.bold}>Bypassing Security Measures: </span>
        You will not bypass or attempt to bypass any measures used to prevent or restrict access to SellCorp.
        <br />
        <br />
        <span className={styles.bold}>Counterfeit or Fraudulent Activities: </span>
        You agree not to advertise or promote any counterfeit companies or engage in any fraudulent activities that infringe upon the copyright, trademark, or other rights of third parties.
        <br />
        <br />
        <span className={styles.bold}>Refusal of Registration: </span>
        SellCorp reserves the right to refuse registration to any user at our discretion.
        <br />
        <br />
        <span className={styles.bold}>User Responsibility for Consequences: </span>
        You are solely responsible for all information that you submit to SellCorp and any consequences that may result from your actions on the platform.
        <br />
        <br />
        <span className={styles.bold}>Penalties and Enforcement: </span>
        We reserve the right to refuse or delete content, restrict or terminate user access, or take any other corrective action if we believe you are violating these Terms of Service or behaving suspiciously. We may also inform other SellCorp users who have been in contact with you and recommend that they exercise caution.
        <br />
        <br />
        <div className={styles.title}>
            Abusing SellCorp
        </div>
        <br />
        <span className={styles.bold}>Community Cooperation: </span>
        SellCorp and its community work collaboratively to ensure the platform functions correctly and remains a safe space for all users. We encourage you to report any issues, offensive content, or violations of our policies through the reporting feature available within the mobile app.
        <br />
        <br />
        <span className={styles.bold}>Reporting Mechanism: </span>
        For abuse, please use the designated reporting channels within the app to ensure timely and appropriate action.
        <br />
        <br />
        <span className={styles.bold}>Action on Abuse: </span>
        We reserve the right to take various measures against users who abuse the SellCorp platform. These measures may include issuing warnings, limiting or terminating access to our services, or removing content. We may also employ technical and legal means to prevent certain users from accessing SellCorp if we believe they are causing problems, infringing on the rights of third parties, or acting in a manner inconsistent with the letter or spirit of our policies.
        <br />
        <br />
        <span className={styles.bold}>Limitation of Liability: </span>
        While we strive to maintain a safe and trustworthy environment, SellCorp is under no obligation to monitor all activities on the platform. As such, we do not assume liability for any unauthorised or unlawful content posted by users. However, we will take appropriate action when violations are reported or identified.
        <br />
        <br />
        <div className={styles.title}>
            Fees and Services
        </div>
        <span className={styles.bold}>Subscription Terms: </span>
        All payments for listings are processed through third-party platforms, including Apple App Store and Google Play Store. The latest pricing and subscription options can be found directly on the SellCorp app listing within the respective app stores. Users should refer to the app store pricing for the most up-to-date fees. Payment is required before a listing becomes active on the platform.
        <br />
        <br />
        <span className={styles.bold}>Billing & Payment Processing: </span>
        All payments are processed through third-party platforms, including Apple App Store and Google Play Store. These platforms handle all billing, transaction fees, and supported payment methods. Prices may vary based on exchange rates, local taxes, and policies of the respective app stores.
        <br />
        <br />
        <span className={styles.bold}>Refunds and Cancellations: </span>
        Refunds for purchases made through Apple App Store or Google Play Store must be requested directly from the respective store, subject to their refund policies. Users can cancel their subscription anytime, but fees already paid are non-refundable.
        <br />
        <br />
        <span className={styles.bold}>Renewal and Continuation: </span>
        Listings will remain active for the duration of the purchased subscription. If a listing is not renewed before expiry, it will be automatically deactivated. Users will receive a renewal notification at least 10 days before their subscription expires.
        <br />
        <div className={styles.title}>
            Display and placement of company profiles
        </div>
        <br />
        <span className={styles.bold}>Criteria for Placement: </span>
        The appearance or placement of company profiles in search and browse results may be influenced by a variety of factors, including but not limited to:
        <br />
        <ul>
            <li>The industry or sector of the company.</li>
            <li>The amount and type of investment sought.</li>
            <li>The company's location.</li>
            <li>The date of the company profile's publication.</li>
            <li>Whether the company profile has all the fields completed.</li>
        </ul>
        <br />
        <span className={styles.bold}>User Acknowledgment: </span>
        By using SellCorp, you acknowledge and agree that the placement of your company profile is at the sole discretion of SellCorp and may be subject to change. We aim to offer a user-friendly experience where users can find the information they need efficiently.
        <br />
        <div className={styles.title}>
            Content
        </div>
        <span className={styles.bold}>Scope of Content: </span>
        SellCorp contains content from us, you, and other users. This may include but is not limited to pitch decks, business descriptions, and investor updates. You are responsible for ensuring that your content is accurate, does not include misleading information, and complies with these Terms of Service as well as all SellCorp policies.
        <br />
        <br />
        <span className={styles.bold}>Intellectual Property: </span>
        Content displayed on or via SellCorp is protected as a collective work and/or compilation, pursuant to copyright laws and international conventions. You agree not to copy, distribute, or modify content from SellCorp without our express written consent. This includes any form of disassembling, decompiling, or reverse engineering of any part of SellCorp.
        <br />
        <br />
        <span className={styles.bold}>User Content Licence: </span>
        By uploading content to SellCorp, you grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide licence to use, copy, modify, and distribute your content in connection with SellCorp and our business. This includes the right to display your content on platforms that are part of the SellCorp corporate group and on social media channels, blogs, and in all types of marketing communications.
        <br />
        <br />
        <span className={styles.bold}>Waiver of Moral Rights: </span>
        You waive all moral rights you have in the content to the fullest extent permitted by law.
        <br />
        <br />
        <span className={styles.bold}>Representation of Rights: </span>
        When you upload content to SellCorp, you represent that you have the legal right to grant us the above-mentioned licence and that doing so does not violate the rights of any third parties.
        <br />
        <br />
        <span className={styles.bold}>No Sale of User Content to Third Parties: </span>
        SellCorp values the trust and privacy of its users. We do not sell your content to third-party companies. Any use of your content will be in accordance with these Terms of Service and our Privacy Policy.
        <br />
        <br />
        <div className={styles.title}>
            Infringement
        </div>
        <br />
        <span className={styles.bold}>Respect for Intellectual Property: </span>
        Do not post content that infringes upon the rights of third parties. This includes, but is not limited to, violations of intellectual property rights such as copyrights, designs, and trademarks. For example, offering counterfeit companies or using copyrighted materials without permission is strictly prohibited.
        <br />
        <br />

        <span className={styles.bold}>Notice of Infringement: </span>
        If you are a lawful owner of intellectual property rights or a legal representative thereof, you may report any content that infringes upon your rights. To do so, please email us at info@sellcorporation.com. Upon receiving your correctly completed Notice of Infringement Form, we will take appropriate action, which may include removing the infringing content.
        <br />
        <br />

        <span className={styles.bold}>Reporting Mechanism: </span>
        To participate in the Notice of Infringement Programme, email us at info@sellcorporation.com. Future reports can be sent to the same email address once we have processed your initial Notice of Infringement Form.
        <br />
        <br />

        <span className={styles.bold}>Limitation of Use: </span>
        The Notice of Infringement Form is intended solely for the lawful owners of the relevant intellectual property rights. It is designed to enable these rights owners to ensure that products and content offered by SellCorp users do not infringe upon their intellectual property.
        <br />
        <br />

        <span className={styles.bold}>For Further Assistance: </span>
        For questions unrelated to intellectual property infringement, you may also reach out to us at info@sellcorporation.com.
        <br />
        <br />
        <div className={styles.title}>
            Liability
        </div>
        <br />
        <span className={styles.bold}>No Responsibility for Third-Party Actions: </span>
        SellCorp is not responsible for the actions, products, services, or content of any third parties. We assume no liability for any damage or loss arising from your use of or reliance on such third-party content.
        <br />
        <br />

        <span className={styles.bold}>Exclusions of Liability: </span>
        We shall not be liable for fraudulent misrepresentation, death, or personal injury except if caused by the negligence of SellCorp, its agents, or employees.
        <br />
        <br />

        <span className={styles.bold}>No Warranty: </span>
        SellCorp does not review the content provided by its users and is not involved in transactions between users. We make no guarantees regarding the accuracy, quality, safety, or legality of user content or what is offered. Additionally, we make no warranty regarding the uninterrupted availability of our services and are not liable for any damages resulting from service interruptions.
        <br />
        <br />

        <span className={styles.bold}>Content Review and Removal: </span>
        To maintain a trustworthy platform, SellCorp reserves the right to review user content from time to time. We may remove or take down content that we, in our sole discretion, deem to be fraudulent, misleading, or in violation of these Terms of Service, without any liability to you. The aim is to ensure that the platform remains a reliable and safe environment for all users.
        <br />
        <br />

        <span className={styles.bold}>Disclaimer: </span>
        All warranties, representations, and conditions, whether express or implied—including those of quality, merchantability, durability, and fitness for a particular purpose—are hereby disclaimed to the fullest extent permitted by law.
        <br />
        <br />

        <span className={styles.bold}>Limitation of Liability: </span>
        In the event that SellCorp is found liable, our liability shall be limited to the greater of:
        <br />
        (a) the total fees you have paid to SellCorp in the 12 months prior to the action giving rise to the liability, or
        (b) £100.
        <br />
        <br />

        <span className={styles.bold}>Survival: </span>
        This disclaimer and limitation of liability shall apply to the fullest extent permitted by law and shall survive the termination of these Terms of Service.

        <div className={styles.title}>
            Disclaimer and Risk Warning
        </div>
        <div className={styles.title}>
            Risk Warning – Article 48 FPO
        </div>
        <br />
        The content of the promotions on SellCorp and its associated websites, services, applications, or tools have not been approved by an authorised person within the meaning of the Financial Services and Markets Act 2000. Reliance on these promotions for the purpose of engaging in any investment activity may expose an individual or a company to a significant risk of losing all of the property or other assets invested.
        <br />
        These presentations are submitted in connection with a possible private placement of limited partnership interests in connection with SellCorp and may not be reproduced or used, in whole or in part, for any other purpose. The limited partnership interests have not been registered in the United States with the Securities and Exchange Commission or with any governmental authority in any other country.
        <br />
        By using the services provided by SellCorp, you acknowledge that you have read, understood, and agree to the above Disclaimer and Risk Warning. For more details, refer to our Risk Warning.
        <br />
        <div className={styles.title}>
            Risk Warning
        </div>
        <br />
        Investing in early-stage companies and startups involves significant risks, including the potential loss of the entire investment. Here are some points you should consider:
        <br />
        <span className={styles.bold}>Investment Loss: </span>
        Investments in startups and early-stage companies are speculative and may result in the total loss of the investment amount.
        <br />
        <br />
        <span className={styles.bold}>Illiquidity: </span>
        Investments made may be illiquid, and you might not be able to sell your investment quickly or at all.
        <br />
        <br />
        <span className={styles.bold}>Lack of Dividends: </span>
        Early-stage companies typically do not pay dividends, and any return on investment may be long-term and uncertain.
        <br />
        <br />
        <span className={styles.bold}>Business Risks: </span>
        Startups face numerous business risks, including competition, market demand, and management experience, which can impact the success of the investment.
        <br />
        <br />
        <span className={styles.bold}>Market Fluctuation: </span>
        The value of investments can go down as well as up due to market fluctuations and economic conditions.
        <br />
        <br />
        <span className={styles.bold}>Diversification: </span>
        Diversification can help manage risk, and investors should consider spreading resources across different investment opportunities.
        <br />
        <br />
        <span className={styles.bold}>Due Diligence: </span>
        Investors are responsible for conducting their own due diligence and assessing the risks before making an investment.
        <br />
        <br />
        <span className={styles.bold}>No Guarantee: </span>
        There is no guarantee of returns, and past performance is not indicative of future results.
        <br />
        <br />
        <span className={styles.bold}>Legal & Tax Implications: </span>
        Consider the legal and tax implications of any investment, and seek advice from qualified professionals if needed.
        <br />
        <br />

        <div className={styles.title}>
            Acknowledgment
        </div>
        <br />
        By proceeding, you acknowledge that you have read, understood, and accepted the risks involved in investing in early-stage companies. You confirm that you are a professional investor or a high-net-worth individual capable of evaluating the merits and risks of investment opportunities.
        <br />
        This document, including the Risk Warning contained herein, is for informational purposes only and does not constitute legal, financial, or investment advice. The information is provided “as is” without warranty of any kind, either expressed or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
        <br />
        Investors should conduct their own due diligence and seek advice from a qualified professional before making any investment decisions. Corporation Sell Limited is not responsible for any loss or damage arising from reliance on the information contained in this document.
        <br />
        <div className={styles.title}>
            Release
        </div>
        <br />
        <span className={styles.bold}>No Involvement in User Disputes: </span>
        SellCorp is not involved in transactions and interactions between users. If you have a dispute with one or more users of SellCorp, you release SellCorp, along with its officers, directors, agents, subsidiaries, joint ventures, and employees, from any and all claims, demands, and damages—both actual and consequential—of every kind and nature, whether known or unknown, arising out of or in any way connected with such disputes.
        <br />
        <br />
        <span className={styles.bold}>Limitation of Liability: </span>
        This release includes, but is not limited to, any disputes regarding the performance, functions, and quality of the services provided by users to each other via the SellCorp platform and extends to any and all claims that may arise under consumer protection or similar legislation.
        <br />
        <br />
        <span className={styles.bold}>Acknowledgment of Risks: </span>
        By using the SellCorp platform, you acknowledge that you may be assuming certain risks, including but not limited to the risk of dealing with strangers, foreign nationals, underage persons, or people acting under false pretences. You assume all risks associated with dealing with other users with whom you come into contact through SellCorp.
        <br />
        <br />
        <div className={styles.title}>
            Third party rights
        </div>
        <br />
        <span className={styles.bold}>Exclusion of Non-Parties: </span>
        In accordance with the Contracts (Rights of Third Parties) Act 1999, a person who is not a party to this Agreement has no right to enforce any term of these Terms of Service.
        <br />
        <br />
        <span className={styles.bold}>Exceptions: </span>
        This does not affect any right or remedy of a third party that exists, or is available, apart from that Act. Specifically, the terms of this Agreement are not intended to and do not confer any rights, benefits, or protections on any person not a party to this Agreement, except as expressly provided herein.
        <br />
        <br />
        <div className={styles.title}>
            Data Protection and Privacy
        </div>
        <br />
        <span className={styles.bold}>Personal and Corporate Information: </span>
        By using SellCorp, you consent to the collection, transfer, storage, and usage of your personal and corporate information on servers located in the United Kingdom, the United States, and Europe, in accordance with Regulation (EU) 2016/679 (General Data Protection Regulation “GDPR”) and other local data protection laws. For more details, refer to our Privacy Policy.
        <br />
        <br />
        <span className={styles.bold}>Independent Data Controllers: </span>
        SellCorp is the primary controller of the personal data collected and processed under these Terms of Service. You, as a user, may also control your own personal data to the extent that you interact with our services, but you do not act as a joint controller with SellCorp. SellCorp is solely responsible for complying with obligations under Regulation (EU) 2016/679 (GDPR) and other applicable data protection laws for the data it controls.
        <br />
        <br />
        <span className={styles.bold}>Obligations for Data Processing and Erasure: </span>
        SellCorp shall process personal data received from you exclusively for the purpose(s) outlined in these Terms of Service and our Privacy Policy. We are committed to erasing the data immediately after fulfilling the respective purpose(s), unless legally required to retain it (e.g., retention obligations under tax or commercial law).
        <br />
        <br />
        <span className={styles.bold}>Cookies and Similar Technologies: </span>
        Like many websites and mobile apps, we use cookies and similar technologies to collect additional usage data and to operate our services.We may receive information when you view content on or otherwise interact with our products and services, even if you have not created an account or are signed out. For more details, please refer to our Privacy Policy.
        <br />
        <br />
        <span className={styles.bold}>Security Measures: </span>
        SellCorp employs security features that prevent users from taking screenshots, copying content, or downloading presentation materials from the platform. This is to ensure the integrity and confidentiality of information shared by entrepreneurs.
        <br />
        <br />
        <span className={styles.bold}>Future Privacy Features: </span>
        SellCorp is planning to introduce features that allow entrepreneurs to set private profiles and will notify entrepreneurs when an investor wishes to review their presentation. This aims to offer an additional layer of privacy for sensitive or confidential information.
        <br />
        <br />
        <span className={styles.bold}>Sensitive Information Advisory: </span>
        If your materials contain sensitive or confidential information, it is advised to either remove such data or provide a simplified presentation. Detailed information should only be shared with investors during the due diligence process.
        <br />
        <br />
        <span className={styles.bold}>Disciplinary Actions: </span>
        Failure to comply with these data protection obligations may result in disciplinary action, including but not limited to, account suspension.
        <br />
        <br />
        <span className={styles.bold}>Access to and Use of Data: </span>
        SellCorp has access to various types of information about its users, which may include personal data. Such information will only be shared with third parties if necessary for the provision of services, or if SellCorp is legally or contractually entitled to do so.
        <br />
        <br />
        <span className={styles.bold}>Data Retention and Deletion: </span>
        Upon the termination or expiry of these Terms of Service, SellCorp will delete the user-provided and generated information from your account, unless we are legally obliged or have a legitimate interest to retain it.
        <br />
        <br />
        <span className={styles.bold}>User Rights: </span>
        For detailed information regarding the processing of personal data by SellCorp, including third-party sharing and your rights as a data subject, please refer to our Privacy Policy.
        <br />
        <br />
        <div className={styles.title}>
            Resolution of disputes
        </div>
        <br />
        If a dispute arises between you and SellCorporation, we strongly encourage you to first contact us directly to seek a resolution by emailing us at info@sellcorporation.com. We will consider reasonable requests to resolve the dispute through alternative dispute resolution procedures, such as mediation or arbitration, as alternatives to litigation.
        <div className={styles.title}>
            General
        </div>
        <br />
        <span className={styles.bold}>Entire Agreement: </span>
        These Terms of Service, along with any other policies posted on SellCorp, constitute the entire agreement between you and SellCorp, superseding any prior agreements or understandings.
        <br />
        <br />
        <span className={styles.bold}>Relationship: </span>
        No agency, partnership, joint venture, employee-employer, or franchiser-franchisee relationship is intended or created by this Agreement.
        <br />
        <br />
        <span className={styles.bold}>Governing Law and Jurisdiction: </span>
        This Agreement is governed by the laws of England and Wales. Any disputes arising from or relating to this Agreement shall be resolved in the courts of England and Wales. Both parties agree to submit to the non-exclusive jurisdiction of the English Courts; for claims falling within the jurisdiction of a County Court, both parties agree to submit to the jurisdiction of the County Court of London.
        <br />
        <br />
        <span className={styles.bold}>Enforcement and Waiver: </span>
        Failure to enforce any provision of this Agreement does not constitute a waiver of the right to enforce it later. If any provision is found to be unenforceable, the remaining provisions will continue in full force and effect.
        <br />
        <br />
        <span className={styles.bold}>Assignment: </span>
        SellCorp may automatically assign this Agreement in its sole discretion, in accordance with the notice provision below.
        <br />
        <br />
        <span className={styles.bold}>Termination: </span>
        Either you or SellCorp may terminate this agreement at any time with notice. Upon termination, you are no longer authorised to access or use SellCorp services and must cease all such activities immediately. Any provisions of this agreement that by their nature should survive termination shall continue in effect post-termination.
        <br />
        <br />
        <span className={styles.bold}>Notice: </span>
        Except for matters related to illegal or infringing content, all notices from you to SellCorp must be sent by registered mail to Corporation Sell Limited, 103 Barney Close, London, SE7 8ST, United Kingdom. SellCorp will send notices to you via the email address you provide or by registered mail. Notices sent by registered mail will be deemed received five days after the date of mailing.
        <br />
        <br />
        <span className={styles.bold}>Amendments: </span>
        We reserve the right to update or amend this Agreement at any time. Updates will take effect when you next use SellCorp, or 30 days after the updated policy is posted on the SellCorp website, whichever comes first. No other amendments to this Agreement will be effective unless made in writing, signed by both parties.
        <br />
        <br />
        <span className={styles.bold}>Accessibility: </span>
        SellCorp is committed to ensuring digital accessibility for people with disabilities. We continually work to improve the user experience for everyone and apply relevant accessibility standards where feasible. If you encounter accessibility barriers while using SellCorp, please contact us.
        <br />
        <br />
        <span className={styles.bold}>Support: </span>
        For assistance with using SellCorp, please refer to the SellCorp Help page.
        <br />
        <br />
        <div className={styles.title}>
            Mobile Devices Terms
        </div>
        <br />
        If you access SellCorp services using a SellCorp Mobile Application (the “Application”), the following terms and conditions (“Terms of Service”) apply to you, in addition to the applicable Mobile Privacy and Legal Notice or End User Licence Agreement, as the case may be. By using the Application, you confirm your agreement to these Terms of Service.
        <br />
        <div className={styles.title}>
            Application Use
        </div>
        <br />
        SellCorp grants you the right to use the Application exclusively for your personal use. You are required to comply with all applicable laws and third-party terms of agreement when using the Application (e.g., your wireless data service agreement). The Application may offer functionalities that differ from those available on the SellCorp website. Your download and use of the Application are at your discretion and risk, and you are solely responsible for any damages to your hardware or data loss that may result from its use.
        <br />
        <div className={styles.title}>
            Intellectual Property – Applications
        </div>
        <br />
        All rights, title, and interest in and to the Application, including copyrights, patents, trademarks, and other proprietary rights, are owned by SellCorp or are licensed to SellCorp. You may not modify, adapt, reverse-engineer, or attempt to extract the Application’s source code. You must also not remove or alter any of SellCorp’s copyright notices, trademarks, or other proprietary rights notices.
        <br />
        <div className={styles.title}>
            Additional Terms
        </div>
        Additional terms and conditions may apply to you based on the mobile device on which the Application is installed:
        <br />
        <div className={styles.title}>
            iOS – Apple
        </div>
        <br />
        This Agreement is between you and SellCorp, not Apple. Apple is not responsible for the Application or its content.
        <br />
        SellCorp grants you the right to use the Application only on an iOS device owned or controlled by you, as permitted by the App Store’s Terms of Service.
        <br />
        Apple has no obligation to furnish any maintenance or support services for the Application.
        <br />
        Apple is not responsible for any third-party intellectual property infringement claims.
        <br />
        <div className={styles.title}>
            Android – Google
        </div>
        <br />
        This Agreement is between you and SellCorp, not Google. Google is not responsible for the Application or its content.
        <br />
        SellCorporation grants you the right to use the Application only on an Android device owned or controlled by you, as permitted by the Google Play Store’s Terms of Service.
        <br />
        Google has no obligation to furnish any maintenance or support services for the Application.
        <br />
        Google is not responsible for any third-party intellectual property infringement claims.
        <br />
        Apple and Google are third-party beneficiaries of these Terms of Service. Upon your acceptance, they have the right (and will be deemed to have accepted the right) to enforce these Terms of Service against you.
        <br />
        <div className={styles.title}>
            Governing Law and Jurisdiction
        </div>
        <br />
        These Terms of Service shall be governed by and construed in accordance with the laws of England and Wales. Any dispute arising under these Terms of Service shall be resolved exclusively by the courts of England and Wales.
        <br />
        <div className={styles.title}>
            Arbitration
        </div>
        <br />
        All disputes arising out of or in connection with this agreement, including any question regarding its existence, validity, or termination, shall be finally settled under the Rules of Arbitration of the International Chamber of Commerce by three arbitrators appointed in accordance with the said Rules. The arbitration shall be conducted in London, England and in the English language. Parties must first attempt to resolve the dispute through good faith negotiation before initiating arbitration.
        <br />
        <div className={styles.title}>
            Severability
        </div>
        <br />
        If any provision of these Terms of Service is found to be invalid or unenforceable, such provision shall be enforced to the maximum extent possible, and the remaining provisions of these Terms of Service shall remain in full force and effect.
        <br />
        <div className={styles.title}>
            Conflict of Terms
        </div>
        <br />
        In the event of any conflict between these Terms of Service and any additional terms that apply to a particular portion of SellCorp, the additional terms will control for that portion of SellCorp.
        <br />
        <div className={styles.title}>
            Modifications
        </div>
        <br />
        We may modify these Terms of Service at any time, and such modifications shall be effective immediately upon posting of the modified Terms of Service within the App. You agree to review the Terms of Service periodically to be aware of such modifications. Your continued use of the App shall be deemed your conclusive acceptance of the modified Terms of Service.
        <br />
        <br />
        <div className={styles.title}>
            Last Updated
        </div>
        <br />
        These Terms of Service were last updated on 29 January 2025.
    </div>
);

export default TermsAndConditions;

const TermsAndConditionssss = () => (
    <div />
);
