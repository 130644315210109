import React from 'react';
import styles from './MarketingPage4.module.scss';
import ThickDivider from '../layout/ThickDivider';
import MarketingPage4Image from '../../assets/images/MarketingPage4Image.png';

const MarketingPage4 = () => (
    <div className={styles.container}>
        <div className={styles.rowContainer}>
            <div>
                <div className={styles.title}>Enjoy on your phone</div>
                <div className={styles.subtitle}>Explore also on tablets.</div>
            </div>
            <div className={styles.imageContainer}>
                <img
                    className={styles.image}
                    src={MarketingPage4Image}
                />
            </div>
        </div>
    </div>
);

export default MarketingPage4;
