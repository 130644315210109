import React, { useState } from 'react';
import { FaAppStoreIos } from 'react-icons/fa';
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import styles from './MarketingNavBar.module.scss';
import iosQRCode from '../../assets/images/ios-qr-code.png';
import androidQRCode from '../../assets/images/android-qr-code.png';
import MarketingLogo from '../MarketingLogo';

const MarketingNavBar = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [showIOSStore, setShowIOSStore] = useState(false);
    const [showAndroidStore, setShowAndroidStore] = useState(false);

    return (
        <div
            className={styles.container}
        >
            <MarketingLogo />
            <div className={styles.rightIconContainer}>
                <div
                    onMouseEnter={() => {
                        setShowIOSStore(true);
                        setShowAndroidStore(false);
                        setMenuActive(false);
                    }}
                    onClick={() => {
                        setShowAndroidStore(false);
                        setShowIOSStore(!showIOSStore);
                    }}
                >
                    <FaAppStoreIos className={styles.appStoreIcon} />
                    {showIOSStore && (
                        <div
                            className={styles.iosQrCodeContainer}
                            onMouseLeave={() => {
                                setShowIOSStore(false);
                            }}
                        >
                            <a
                                href="https://apps.apple.com/ro/app/sellcorp/id6446894441"
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => {
                                    setShowAndroidStore(false);
                                    setShowIOSStore(false);
                                }}
                            >
                                <img
                                    className={styles.qrCode}
                                    src={iosQRCode}
                                />
                                <div className={styles.qrCodeText}>
                                    SellCorp on App store
                                </div>
                            </a>

                        </div>
                    )}
                </div>
                <div
                    onMouseEnter={() => {
                        setShowAndroidStore(true);
                        setShowIOSStore(false);
                        setMenuActive(false);
                    }}
                    onClick={() => {
                        setShowAndroidStore(!showAndroidStore);
                        setShowIOSStore(false);
                    }}
                >
                    <IoLogoGooglePlaystore className={styles.playStoreIcon} />
                    {showAndroidStore && (
                        <div
                            className={styles.androidQrCodeContainer}
                            onMouseLeave={() => {
                                setShowAndroidStore(false);
                            }}
                        >
                            <a
                                href="https://play.google.com/store/apps/details?id=com.sellcorporationapp&hl=en_US"
                                target="_blank"
                                rel="noreferrer"
                                onClick={() => {
                                    setShowAndroidStore(false);
                                    setShowIOSStore(false);
                                }}
                            >
                                <img
                                    className={styles.qrCode}
                                    src={androidQRCode}
                                    width={200}
                                    height={200}
                                />
                                <div className={styles.qrCodeText}>
                                    SellCorp on Play store
                                </div>
                            </a>
                        </div>
                    )}
                </div>
                <div
                    className={styles.glowOnHover}
                    onClick={() => {
                        window.location.href = 'https://app.sellcorporation.com/';
                    }}
                >
                    Log in
                </div>
            </div>
        </div>
    );
};

export default MarketingNavBar;
