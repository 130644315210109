import React, { useState } from 'react';
import styles from './CookieBanner.module.scss';

const CookieBanner = () => {
    const [consentProvided, setConsentProvided] = useState(false);
    const cookieConsent = document.cookie.includes('cookie-consent=true');

    if (cookieConsent || consentProvided) {
        return null;
    }

    return (
        <div
            className={styles.container}
        >
            <div>
                <div className={styles.title}>
                    SellCorporation does not use any analytics or tracking cookies.
                </div>
                <div className={styles.subtitle}>
                    We only use cookies that are necessary for proper website functionality and security.
                </div>
                <div className={styles.subtitle}>
                    By using this website, you agree to our use of cookies.
                </div>
            </div>
            <div>
                <button
                    className={styles.button}
                    onClick={() => {
                        setConsentProvided(true);
                        document.cookie = 'cookie-consent=true; max-age=2592000';
                    }}
                >
                    Got it
                </button>
            </div>
        </div>
    );
};

export default CookieBanner;
