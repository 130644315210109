import React from 'react';
import styles from './MarketingPage2.module.scss';
import MarketingPage2Background from '../../assets/images/MarketingPage2Background.png';

const MarketingPage2 = () => (
    <div className={styles.container}>
        <img
            className={styles.backgroundImage}
            src={MarketingPage2Background}
        />
        <div className={styles.contentContainer}>
            <div className={styles.title}>
                The SellCorp you trust, now with flexible publishing plans to suit your needs.
            </div>
            <div className={styles.subtitle}>
                • 1 month: £25/month - Full price, billed monthly.
            </div>
            <div className={styles.subtitle}>
                • 3 months: £15/month - Save 40%, pay £45 billed quarterly.
            </div>
            <div className={styles.subtitle}>
                • 12 months: £10/month - Save 60%, pay £120 billed annually.
            </div>
            <div className={styles.subtitle}>
                Stay active longer to maximise your chances of being discovered by active investors. Cancel anytime with no commitment.
            </div>
            {/* <a
                className={styles.subtitleCta}
                href="https://sellcorporationapp.page.link/founders"
                target="_blank"
                rel="noreferrer"
            >
                Learn More
            </a> */}
        </div>
    </div>
);

export default MarketingPage2;

// The SellCorp you trust, now with flexible publishing plans to suit your needs.
// • 1 month: £25/month - Full price, billed monthly.
// • 3 months: £15/month - Save 40%, pay £45 billed quarterly.
// • 12 months: £10/month - Save 60%, pay £120 billed annually.
// Stay active longer to maximise your chances of being discovered by active investors. Cancel anytime with no commitment.
