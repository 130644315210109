import React, {
    Dispatch, FC, SetStateAction, useState,
} from 'react';
import styles from './MarketingPage8.module.scss';
import ThickDivider from '../layout/ThickDivider';
import { HiPlus } from 'react-icons/hi';
import { FaChevronRight } from 'react-icons/fa6';
import useDevice from '../../hooks/useDevice';
import { size } from 'lodash';

const data = [
    {
        title: 'What is SellCorp?',
        content: 'SellCorp is a mobile discovery platform that connects entrepreneurs seeking funding with professional investors looking for high-potential startups. We provide a simple, intuitive space where founders can list their businesses and investors can manually browse, filter, and explore companies based on their own preferences.\nUnlike other platforms, SellCorp does not use algorithms to match investors and companies, nor do we facilitate the investment process. All communications, due diligence, and negotiations take place directly between investors and founders outside of the platform. SellCorp is designed to streamline the discovery process, making it easier for investors to find relevant opportunities and for founders to get in front of the right people.',
    },
    {
        title: 'How likely am I to successfully meet investors?',
        content: "The SellCorp app provides a platform that connects entrepreneurs with professional investors, offering you the opportunity to showcase your business to a targeted audience actively seeking investment opportunities. However, success in securing meetings or investments depends on several factors, including the quality of your pitch, the appeal of your business model, and alignment with investors' interests.\nWhile SellCorp helps you gain visibility, the outcome will ultimately rely on how well your company resonates with the investors browsing the platform. We encourage founders to present clear, compelling profiles to maximize their chances of attracting interest. Remember, all interactions and discussions happen directly between investors and founders outside of SellCorp.",
    },
    {
        title: 'How much does SellCorp cost?',
        content:
            <span>
                <span className={styles.bold}>For Founders:</span>
                <br />
                The SellCorp app now offers a flexible subscription package to help you showcase your business to potential investors. Choose from the following pricing options (prices are listed in GBP):
                <br />
                <ul>
                    <li className={styles.bold}>£25 for 1 month</li>
                    <li className={styles.bold}>£45 for 3 months</li>
                    <li className={styles.bold}>£120 for 12 months</li>
                </ul>
                Your subscription provides full access to all essential tools and features, including detailed analytics, an intuitive listing process, and direct exposure to a network of active VCs, Angels, and Family Offices. Please note that prices may vary slightly depending on your App Store country and current currency conversion rates. Additionally, keep an eye out for promotional vouchers that might offer free months.
                <br />
                <br />
                <span className={styles.bold}>For Investors:</span>
                <br />
                Creating an account and browsing the SellCorp platform remains completely free. Explore a wide range of innovative companies at no cost, making it easier than ever to discover your next big investment opportunity.
            </span>,
        // content: `For Founders:\nThe SellCorp app now offers a flexible subscription package to help you showcase your business to potential investors. Choose from the following pricing options (prices are listed in GBP):£25 for 1 month£45 for 3 months£120 for 12 monthsYour subscription provides full access to all essential tools and features, including detailed analytics, an intuitive listing process, and direct exposure to a network of active VCs, Angels, and Family Offices. Please note that prices may vary slightly depending on your App Store country and current currency conversion rates. Additionally, keep an eye out for promotional vouchers that might offer free months.\n\nFor Investors:\nCreating an account and browsing the SellCorp platform remains completely free. Explore a wide range of innovative companies at no cost, making it easier than ever to discover your next big investment opportunity.`,
    },
    {
        title: 'What privacy measures does SellCorp have in place?',
        content: "At SellCorp, we take your privacy seriously. We ensure that your data is secure and that you have complete control over what you share. To protect sensitive information, we don't allow screenshots within the platform, and only registered users can access the content. If you're not ready to share a full pitch deck, you can upload a teaser to give investors a preview of your opportunity.",
    },
    {
        title: 'How do I cancel?',
        content: "To cancel your SellCorp subscription, you'll need to do so through your Apple Store or Google Play Store account, as all subscriptions are managed by the respective app store. Simply go to your account settings on your device, find your subscriptions, and select SellCorp to cancel. Your subscription will remain active until the end of the current billing period. If you have any questions or need assistance, our support team is available to help guide you through the process.",
    },
    {
        title: 'What can I see on SellCorp?',
        content: 'SellCorp caters to companies across all industries, with a particular focus on sectors like SpaceTech, Blockchain, Decentralised Finance, Web3, AgTech, FoodTech, DeepTech, AI, Machine Learning, Hardware, and more. Our platform is designed to support innovation across the board, so no matter your industry, you can find the right investors here.',
    },
    {
        title: 'Is SellCorp available only on mobile?',
        content: "Yes, SellCorp is exclusively available on mobile. We've designed our platform with mobile-first in mind to ensure that you can manage your fundraising efforts or discover companies on the go, from anywhere in the world.\nListing a company can be done from the web.",
    },
    {
        title: 'What are the usual ticket sizes amongst members?',
        content: 'The typical investment ticket sizes on SellCorp can vary widely depending on the stage of the company and the preferences of investors. Generally, ticket sizes can range from £50,000 to £5 million, with early-stage startups attracting smaller investments and later-stage ventures securing larger amounts. Angel investors may contribute lower amounts, typically in the £50,000 to £500,000 range, while venture capital firms and institutional investors tend to invest in larger rounds, often upwards of £1 million.\nKeep in mind, these ranges can vary based on industry, company traction, and specific investor profiles within the platform.',
    },
    {
        title: 'What makes SellCorp different from other platforms like Crunchbase or Pitchbook?',
        content: 'Unlike other platforms that rely on scraped or third-party data, SellCorp features founder-listed companies, ensuring accurate, up-to-date information directly from the source.',
    },
];

interface Props {
    setShowBanner: Dispatch<SetStateAction<boolean>>;
}

const MarketingPage8: FC<Props> = ({ setShowBanner }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const { isAndroid, isIOS } = useDevice();

    return (
        <div className={styles.container}>

            <div className={styles.title}>Frequently Asked Questions</div>
            <div className={styles.faqContainer}>
                {data.map((item, index) => {
                    const visible = activeIndex === index;
                    return (
                        <div
                            key={item.title}
                            className={styles.itemContainer}
                        >
                            <div
                                className={`${styles.rowContainer} ${visible ? styles.visible : ''}`}
                                onClick={() => {
                                    if (activeIndex === index) {
                                        setActiveIndex(null);
                                        return;
                                    }
                                    setActiveIndex(index);
                                }}
                            >
                                <div className={styles.question}>{item.title}</div>
                                <HiPlus
                                    className={`${styles.plusIcon} ${visible ? styles.rotate : ''}`}
                                />
                            </div>
                            <div className={`${styles.content} ${visible ? styles.show : ''}`}>
                                {item.content}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className={styles.inputContainer}>
                <div className={styles.inputTitle}>Ready to explore?</div>
                <div
                    className={styles.glowOnHover}
                    onClick={() => {
                        if (isAndroid) {
                            window.open('https://play.google.com/store/apps/details?id=com.sellcorporationapp&hl=en_US', '_blank');
                        } else if (isIOS) {
                            window.open('https://apps.apple.com/ro/app/sellcorp/id6446894441', '_blank');
                        } else {
                            setShowBanner(true);
                        }
                    }}
                >
                    Get Started
                    <FaChevronRight className={styles.buttonChevron} />
                </div>
            </div>
            <ThickDivider />
        </div>
    );
};

export default MarketingPage8;
