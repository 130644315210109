import React from 'react';
import styles from './MarketingPage11.module.scss';
import ThickDivider from '../layout/ThickDivider';
import YouTube from 'react-youtube';

const MarketingPage11 = () => (
    <div className={styles.container}>
        <ThickDivider />
        <YouTube
            videoId="dgvaFbfXDPw"
            className={styles.youtube}
            iframeClassName={styles.youtubeIFrame}
        />
        <ThickDivider />
    </div>
);

export default MarketingPage11;
