import React from 'react';
import styles from './MarketingPage9.module.scss';
import ThickDivider from '../layout/ThickDivider';
import MarketingPage9Image from '../../assets/images/MarketingPage9Image.png';

const MarketingPage9 = () => (
    <div className={styles.container}>
        <div className={styles.rowContainer}>
            <div>
                <div className={styles.title}>{'Watch your favourites\nas they grow'}</div>
                <div className={styles.subtitle}>Stay updated on a plane, train, office, or the loo...</div>
            </div>
            <div className={styles.imageContainer}>
                <img
                    className={styles.image}
                    src={MarketingPage9Image}
                />
            </div>
        </div>
        {/* <ThickDivider /> */}
    </div>
);

export default MarketingPage9;
